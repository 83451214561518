import React, { useRef } from "react";
import { images } from "../javascript/imageImports";

const Price = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const handleMouseEnter = (ref) => {
    ref?.current.classList.add("glow-category");
  };
  const handleMouseLeave = (ref) => {
    ref?.current.classList.remove("glow-category");
  };

  return (
    <div className="prices-main-container text-white" id="prices">
      <div
        className="price-category "
        ref={ref1}
        onMouseEnter={() => handleMouseEnter(ref1)}
        onMouseLeave={() => handleMouseLeave(ref1)}
      >
        <h1 className="text-center ">School Category</h1>
        <div className="entry gold">
          <h2>Champions</h2>
          <img src={images.gold} alt="" />
          <div className="glow"></div>
          <div className="price-money price-money-gold">
            <h4>Rs. 100,000</h4>
          </div>
        </div>
        <div className="prices-entries">
          <div className="entry">
            <h3>1st Runners up</h3>
            <img src={images.silver} alt="" />
            <div className="price-money price-money-silver">
              <h4>Rs. 75,000</h4>
            </div>
          </div>
          <div className="entry">
            <h3>2nd Runners up</h3>
            <img src={images.bronze} alt="" />
            <div className="price-money price-money-bronze">
              <h4>Rs. 50,000</h4>
            </div>
          </div>
        </div>
      </div>
      <div
        className="price-category"
        ref={ref2}
        onMouseEnter={() => handleMouseEnter(ref2)}
        onMouseLeave={() => handleMouseLeave(ref2)}
      >
        <h1 className="text-center">University Category</h1>
        <div className="entry gold">
          <h2>Champions</h2>
          <img src={images.gold} alt="" />
          <div className="glow"></div>

          <div className="price-money price-money-gold">
            <h4>Rs. 100,000</h4>
          </div>
        </div>
        <div className="prices-entries">
          <div className="entry">
            <h3>1st Runners up</h3>
            <img src={images.silver} alt="" />
            <div className="price-money price-money-silver">
              <h4>Rs. 75,000</h4>
            </div>
          </div>
          <div className="entry">
            <h3>2nd Runners up</h3>
            <img src={images.bronze} alt="" />
            <div className="price-money price-money-bronze">
              <h4>Rs. 50,000</h4>
            </div>
          </div>
        </div>
      </div>

      <div
        className="price-category"
        ref={ref3}
        onMouseEnter={() => handleMouseEnter(ref3)}
        onMouseLeave={() => handleMouseLeave(ref3)}
      >
        <h1 className="text-center">Open Category</h1>
        <div className="entry gold">
          <h2>Champions</h2>
          <img src={images.gold} alt="" />
          <div className="glow"></div>

          <div className="price-money price-money-gold">
            <h4>Rs. 120,000</h4>
          </div>
        </div>
        <div className="prices-entries">
          <div className="entry">
            <h3>1st Runners up</h3>
            <img src={images.silver} alt="" />
            <div className="price-money price-money-silver">
              <h4>Rs. 80,000</h4>
            </div>
          </div>
          <div className="entry">
            <h3>2nd Runners up</h3>
            <img src={images.bronze} alt="" />
            <div className="price-money price-money-bronze">
              <h4>Rs. 50,000</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Price;
