import React from "react";
import { images } from "../javascript/imageImports";

const ChallengeDetails = () => {
  return (
    <section id="challengedetails" className="my-5 p-4 scroll-margin">
      <div className="fixed-background py-5">
        <div className="row gap-5">
          {/* School Challenge Card */}
          <div
            className="reveal-bottom col bg-transparent rounded-4"
            style={{
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              cursor: "pointer",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
              e.currentTarget.style.boxShadow = "0 8px 15px rgba(0, 0, 0, 0.3)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.boxShadow = "none";
            }}
          >
            <div className="row my-5">
              <div className="col-md-12 mx-auto text-center text-muted">
                <h2 className="topic display-6 section-heading text-warning text-uppercase">
                  School Challenge Details
                </h2>
                <hr className="light my-4" />
                <p className="my-4 mx-2 text-light" style={{ textAlign: "justify" }}>
                Prepare yourselves for an engaging competition structured in three phases. The
initial two segments involve online participation through the utilization of the Webots
robot simulation software, enabling control over virtual robots. In the subsequent and
concluding phase, an in-person gathering will transpire. Notably, participants won't
need to physically construct robots, as we'll supply the necessary robotic entities.
Instead, the objective lies in successfully programming these robots to execute
specific movements within a designated play area that we will provide.

                </p>
                <div>
                  <a
                    className="neon-button-three btn p-3 px-5 mt-3 btn-lg reveal-left"
                    href="https://docs.google.com/document/d/12Z3MFy8um1E2Be1w21bfXgmNmXZal27Y4ZVP0qrZ9Kg/edit?usp=drivesdk"
                    target="_blank"
                    rel="noreferrer"
                    style={{ transition: "transform 0.2s ease" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "scale(1.1)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                    }}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    More Details...
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Undergraduate Challenge Card */}
          <div
            className="challenge-details reveal-bottom col bg-transparent rounded-4"
            style={{
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              cursor: "pointer",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
              e.currentTarget.style.boxShadow = "0 8px 15px rgba(0, 0, 0, 0.3)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.boxShadow = "none";
            }}
          >
            <div className="row my-5">
              <div className="col-md-12 mx-auto text-center text-muted">
                <h2 className="topic display-6 section-heading text-warning text-uppercase">
                  Undergraduate Challenge Details
                </h2>
                <hr className="light my-4" />
                <p className="my-4 mx-2 text-light" style={{ textAlign: "justify" }}>
                Prepare yourselves for an engaging competition structured in three phases. The
initial two segments involve online participation through the utilization of the Webots
robot simulation software, enabling control over virtual robots. In the subsequent and
concluding phase, an in-person gathering will transpire. Notably, participants won't
need to physically construct robots, as we'll supply the necessary robotic entities.
Instead, the objective lies in successfully programming these robots to execute
specific movements within a designated play area that we will provide.

                </p>
                <div>
                  <a
                    className="neon-button-three btn p-3 px-5 mt-3 btn-lg reveal-left"
                    href="https://docs.google.com/document/d/1u_KjnrSA-jPuiUvvTb6JFBh4OotIM2OZtLvOz6ONp78/edit?usp=drivesdk"
                    target="_blank"
                    rel="noreferrer"
                    style={{ transition: "transform 0.2s ease" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "scale(1.1)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                    }}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    More Details...
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Open Challenge Card */}
          <div
            className="container challenge-details reveal-bottom bg-transparent rounded-4"
            style={{
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              cursor: "pointer",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
              e.currentTarget.style.boxShadow = "0 8px 15px rgba(0, 0, 0, 0.3)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.boxShadow = "none";
            }}
          >
            <div className="row my-5">
              <div className="col-md-10 mx-auto text-center text-muted">
                <h2 className="topic display-6 section-heading text-warning text-uppercase">
                  Open Challenge Details
                </h2>
                <hr className="light my-4" />
                <p className="my-4 text-light" style={{ textAlign: "justify" }}>
                Gear up for an electrifying showdown in the Open Category, featuring the highly
anticipated Battlebots Challenge. This segment is designed to test your engineering
acumen, strategic planning, and competitive spirit in a no-holds-barred knockout
tournament.
Participants are invited to design and build their own combat robots, adhering to the
competition's technical specifications and safety regulations. The tournament will
culminate in thrilling Grand Finale Day, where these robots will face off in
head-to-head battles, aiming to outmaneuver, outwit, and overpower their opponents.
The challenge is simple yet intense: construct a robot capable of dominating the
arena, surviving fierce clashes, and emerging as the ultimate champion. Victory
hinges on the ingenuity of your design, the efficiency of your programming, and your
ability to strategize under pressure.
                </p>
                <div>
                  <a
                    className="neon-button-three btn p-3 px-5 mt-3 btn-lg reveal-left"
                    href="https://docs.google.com/document/d/1fwg8040b7bO62LUIAcw_0AMLn5iADH7ny26bOV0Q82U/edit?usp=drivesdk"
                    target="_blank"
                    rel="noreferrer"
                    style={{ transition: "transform 0.2s ease" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "scale(1.1)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                    }}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    More Details...
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        
    </section>
  );
};

export default ChallengeDetails;
