import React from "react";

const Contact = () => {
  return (
    <section id="contact" className="my-5 p-4 scroll-margin">
      <div className="container text-center reveal-bottom">
        <div className="row text-muted">
          <hr className="my-4" />
          {/* Adjust the flex layout with justify-between for full width */}
          <div className="d-flex justify-content-between flex-wrap">
            {/* Left Side: Contact Us Title, Address, and Icons */}
            <div className="col-sm-12 col-md-6 mx-0 text-start">
            <p className="lead text-muted my-4">
                For further inquiries and clarifications, feel free to contact us.
              </p>
              <h2 className="topic display-6 section-heading text-white">
                Contact Us
              </h2>
              
              {/* Address below Contact Us */}
              <address className="lead text-warning">
                Department of Computer Science and Engineering,
                <br />
                University of Moratuwa,
                <br />
                Katubedda,
                <br />
                Sri Lanka.
              </address>
              <div className="col-sm-12 text-start">
                <div className="icons my-4 d-flex">
                  <a href="https://www.facebook.com/pg/ieslrobogame" className="me-4">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </div>
              </div>
            </div>

            {/* Right Side: WhatsApp Contacts */}
            <div className="col-sm-12 col-md-6 mx-0 my-4 text-start">
              <ul className="list-unstyled">
                {/* Chairperson */}
                <li className="mb-3 mt-1 d-flex align-items-start">
                  <a
                    href="https://wa.me/+94768870099"
                    className="text-decoration-none text-warning d-flex align-items-center"
                  >
                    <div>
                    
                      <span className="text-warning">Dewmina Wijekoon</span>
                      <br />
                      <small className="text-muted">Chairperson</small>
                      <br />
                      <small className="text-muted">076 887 0099</small>
                    </div>
                  </a>
                </li>
                {/* Vice Chairperson */}
                <li className="mb-3 d-flex align-items-start">
                  <a
                    href="https://wa.me/+94777867058"
                    className="text-decoration-none text-warning d-flex align-items-center"
                  >
                    <div>
                      <span className="text-warning">Varun Poobalaraja</span>
                      <br />
                      <small className="text-muted">Vice Chairperson</small>
                      <br />
                      <small className="text-muted">077 786 7058</small>
                    </div>
                  </a>
                </li>
                {/* Delegate Lead */}
                <li className="mb-3 d-flex align-items-start">
                  <a
                    href="https://wa.me/+94705619190"
                    className="text-decoration-none text-warning d-flex align-items-center"
                  >
                    <div>
                      <span className="text-warning">Chamathsara Navodi</span>
                      <br />
                      <small className="text-muted">Delegate Handling Committee Lead</small>
                      <br />
                      <small className="text-muted">070 561 9190</small>
                    </div>
                  </a>
                </li>
                {/* Selection Lead */}
                <li className="mb-3 d-flex align-items-start">
                  <a
                    href="https://wa.me/+94742828231"
                    className="text-decoration-none text-warning d-flex align-items-center"
                  >
                    <div>
                      <span className="text-warning">Sasmitha Jayasinghe</span>
                      <br />
                      <small className="text-muted">Selection & Technology Committee Lead</small>
                      <br />
                      <small className="text-muted">074 282 8231</small>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Footer */}
          <div className="footer mt-5">
            <div>
              <a
                style={{ fontSize: "20px" }}
                href="mailto:mailtonativecode@gmail.com"
                target="_blank"
                rel="noreferrer"
                className="text-decoration-none"
              >
                Developed by NativeCode
              </a>
            </div>
            <br />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;